import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import "./OfficeBanner.scss"
import { AltSiteName } from "../utils";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import $ from 'jquery';
const OfficeBanner = (props) => {
    const officeitem = props.data
    let processedImages = JSON.stringify({});
    if (officeitem?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = officeitem.imagetransforms.Banner_Image_Transforms;
    }
    function gotohash(event) {
        var thishash = event;
        $('html, body').animate({
          scrollTop: $(thishash).offset().top - 30
        }, 1000);
    }
    return (
        <React.Fragment>

            <section className="office-banner">
                <div className={`banner-img ${officeitem.Name.toLowerCase().replace(/\s/g, '')}`}>
                <ImageTransform imagesources={officeitem.Banner_Image.url} renderer="srcSet" imagename="our-offices.Banner_Image.commontile" attr={{ alt: officeitem.Banner_Image.alternativeText?officeitem.Banner_Image.alternativeText:officeitem.Name+AltSiteName}} imagetransformresult={processedImages} id={officeitem.id}/>  
                </div>
                <div className="banner-info">
                    
                        <Container>
                            <Row>
                                <Col>
                                    <div className="box-wrapper">
                                        <div className="box">
                                            <h1>
                                            Estate Agents & Letting Agents in {officeitem.Name}
                                            </h1>
                                            <h4>{officeitem.Office_Address}</h4>
                                        </div>
                                        <div className="box-content">
                                            {officeitem.Sales_Phone_Number &&
                                            <div className="box">
                                                <h3>
                                                    Sales
                                                </h3>
                                                <a href={`tel:${officeitem.Sales_Phone_Number}`} className="tel">{officeitem.Sales_Phone_Number}</a>
                                                <a href="javascript:;" onClick={() => gotohash('#branch-form-section .contact-branch')} className="mail">{officeitem.Sales_Email_Address}</a>
                                            </div>
                                            }
                                            {officeitem.Lettings_Phone_Number &&
                                            <div className="box">
                                                <h3>
                                                    Lettings
                                                </h3>
                                                <a href={`tel:${officeitem.Lettings_Phone_Number}`} className="tel">{officeitem.Lettings_Phone_Number}</a>
                                                <a href="javascript:;" onClick={() => gotohash('#branch-form-section .contact-branch')} className="mail">{officeitem.Lettings_Email_Address}</a>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    
                </div>
            </section>
        </React.Fragment>
    )
}
export default OfficeBanner;