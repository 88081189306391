import React from "react";
import Helmet from "react-helmet";
import { convert } from "html-to-text"

const FAQSchema = ({ faq }) => {

  const faqList = faq.map((faqItem, index) => {
    const faqItemContent = convert(faqItem.FAQ_Content)
    
    return {
      "@type": "Question",
      name: faqItem.FAQ_Title,
      acceptedAnswer: {
        "@type": "Answer",
        text: faqItemContent,
      },
    };
  });

  const schemaJSON = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqList,
  };

  const schema = JSON.stringify(schemaJSON);

  return (
    <div className="faq-section">
      <Helmet>
        <script type="application/ld+json">{schema}</script>
      </Helmet>
    </div>
  );
};
export default FAQSchema;
