import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import "./DetailsPage.scss";
import parse from 'html-react-parser';
import { AltSiteName, AreaguideBaseURL, SaleURL, LetURL } from "../../utils";
import GetCTA from "../../getcta";
import OfficeContactForm from '../../forms/office-contact-form';
const OfficeDetailInfo = (props) => {
const officeitem = props.data
return (
<React.Fragment>
<section className="details-page">
<Container>
<Row>
<Col>

<div className="details-content">
<div className="details-wrapper">
<div className="details-info rental-agents">
{parse(officeitem.About_Office)}
</div>
<div className="bottom-ctas">
    <div className="btn-wrapper">
        <Link className="btn btn-primary" to={`${SaleURL}/${officeitem.Name.toLowerCase().replace(/ /g,"-")}/`}>Property for sale in {officeitem.Name}</Link>
        <Link className="btn btn-primary" to={`${LetURL}/${officeitem.Name.toLowerCase().replace(/ /g,"-")}/`}>Property to let in {officeitem.Name}</Link>
    </div>
</div>
<div className="opening-hours">
{officeitem.Opening_Hour_Image &&
<div className="opening-img">
    <picture className="img-zoom">
        <source media="(min-width:992px)" srcSet={officeitem.Opening_Hour_Image.url} />
        <img src={officeitem.Opening_Hour_Image.url} alt={officeitem.Opening_Hour_Image.alternativeText?officeitem.Opening_Hour_Image.alternativeText:officeitem.Name+'Opening Hours'+AltSiteName} />
    </picture>
</div>
}
<div className="opening-details">
    <h3>Opening Hours</h3>
    <ul className="hours-details">
        {officeitem.Office_Opening_Hours && officeitem.Office_Opening_Hours.map((item) => {
        return<>
        <li>{item.Day}</li>
        <li className="hours">{item.Time}</li>
        </>
        })}                                                    
    </ul>
    <span className="notice">*All calls are forwarded to our customer care centre</span>
    {officeitem.Get_Directions_Link &&
    <a href={officeitem.Get_Directions_Link} target="_blank" className="get">
        Get directions
    </a>
    }
</div>
</div>
</div>
<div className="content-wrapper" id="branch-form-section">
<div className="contact-branch ">
<div className="branch-info" >
    <h2>Contact Branch</h2>
    <OfficeContactForm officeaddress={officeitem.Office_Address} salesphone={officeitem.Sales_Phone_Number} letphone={officeitem.Lettings_Phone_Number} officename={officeitem.Name} salesemail={officeitem.Sales_Email_Address} letemail={officeitem.Lettings_Email_Address} />
    {/* <Form>
        <Form.Group controlId="formBasicEmail">
            <Form.Control type="text" placeholder="Your name" />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
            <Form.Control type=" email" placeholder="your email" />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
            <Form.Control type="number" placeholder="Your phone number" />
        </Form.Group>

        <Form.Group controlId="exampleForm.ControlTextarea1" >
            <Form.Control placeholder="Your message" as="textarea" rows={3} className="area" />
        </Form.Group>
        {['checkbox'].map((type) => (
            <div key={`inline-${type}`} className="mb-2">
                <Form.Check
                    inline
                    label="Get emails with the latest news and information on the local
            property market, 
        our products and services. You can unsubscribe at any time."
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                />
            </div>
        ))}

        {['checkbox'].map((type) => (
            <div key={`inline-${type}`} className="mb-3 data">
                <Form.Check
                    inline
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                />
                <span className="form-check-label">
                    I have read and agree to the <Link to="#"> Terms and
                        Conditions, </Link> <Link to="#"> Privacy Policy </Link> and <Link to="#"> Cookies Policy. </Link>
                </span>
            </div>
        ))}

        <Button variant="primary" type="submit">
            Submit Enquiry
        </Button>
    </Form> */}
</div>
<div className="right-block">
        <div className="appraisal-bar ">
          <div className="left-block">
            <h2>What’s my property worth?</h2>
            <p>
              Selling or Letting? Get a free and up-to-date valuation from one of our experts.
            </p>
          </div>
          <div className="right-block">
            <Link to="/property-valuation/" className="btn">
              Book your market appraisal
            </Link>
          </div>
        </div>
      </div>
{officeitem.Office_Useful_Links?
<div className="useful-links d-none d-md-block">
    <h3>Useful Links</h3>
    {officeitem.Office_Useful_Links && officeitem.Office_Useful_Links.map((item) => {
    return<>
    <li>
        {item.Custom_Useful_Link?
        <Link to={item.Custom_Useful_Link}>{item.Useful_Link_Label}</Link>
        :
        <GetCTA link={item.Useful_Link_Menu && item.Useful_Link_Menu.Primary_URL} Label={item.Useful_Link_Label}/>
        }
    </li>                                                
    </>
    })}
</div>
:""}
</div>
{officeitem.Living_Module ?
<div className="living ">
<div className="about-living">
    <h2>{officeitem.Living_Module.Living_Module_Title}</h2>
    {parse(officeitem.Living_Module.Living_Module_Intro)}
</div>

<div className="living-details">
    <div className="living-img">
        <picture>
            <img src={officeitem.Living_Module.Living_Module_Image.url} alt={officeitem.Living_Module.Living_Module_Image.alternativeText?officeitem.Living_Module.Living_Module_Image.alternativeText:officeitem.Living_Module.Living_Module_Title+AltSiteName} />

        </picture>
    </div>
    {officeitem.Living_Module.Living_Module_Info &&
    <div className="living-info">
        <h2>{officeitem.Name} Council tax bands</h2>
        <ul className="living-list">
            {officeitem.Living_Module.Living_Module_Info &&
            officeitem.Living_Module.Living_Module_Info.map((item)=>{
            return<>
                <li className="title">{item.Info_List_Title}</li>
                <li>{item.Info_List_Value}</li>
            </>
            })}           
        </ul>
        {/* <Link to={`${AreaguideBaseURL}/${officeitem.Living_Module.Select_Areaguide && officeitem.Living_Module.Select_Areaguide.URL}-area-guides`} className="notice">
            View {officeitem.Name} area guide
        </Link> */}
        <p>*Please note your exact council tax rate may vary depending on your Parish</p>
    </div>
    }
</div>

</div>
:""}
</div>
</div>

</Col>
</Row>
</Container>
</section>
</React.Fragment>
)
}
export default OfficeDetailInfo;