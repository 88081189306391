import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Card } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import parse from 'html-react-parser';
import GetCTA from "../../getcta";
import "./Faq.scss"
import FAQSchema from "./Faqschema.js"
const OfficeFaq = (props) => {
    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext)

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        )
        const isCurrentEventKey = currentEventKey === eventKey

        return (
            <button
                type="button"
                className={
                    isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
                }
                onClick={decoratedOnClick}
            >
                {children}
                <i
                    className={
                        isCurrentEventKey ? "icon-minus-footer " : "icon-plus-footer"
                    }
                ></i>
            </button>
        )
    }
    return (
        <React.Fragment>

            <section className="faq-section">
                <FAQSchema faq={props.faq && props.faq}/>
                    <Container>
                        <Row>
                            <Col>
                                <h2>
                                    Frequently Asked Questions
                                </h2>
                                <div className="accordion-section">
                                    <Accordion >
                                        {props.faq && props.faq.map((item) => {
                                        return<>  

                                        <Card>
                                            <div className="accordion-header card-header">
                                                <ContextAwareToggle eventKey={item.id} className="accordion-header">
                                                    <h3>{item.FAQ_Title}</h3>
                                                </ContextAwareToggle>
                                            </div>
                                            <Accordion.Collapse eventKey={item.id}>
                                                <div className="accordion-card accordion-card-first card-body">
                                                   {item.FAQ_Content && parse(item.FAQ_Content)}
                                                </div>
                                            </Accordion.Collapse>
                                        </Card>
                                        </>
                                        })}                                 
                                    </Accordion>
                                </div>

                                {props.usefullinks.Office_Useful_Links?
                                    <div className="useful-links d-md-none">
                                    <h3>Useful Links</h3>
                                    {props.usefullinks.Office_Useful_Links && props.usefullinks.Office_Useful_Links.map((item) => {
                                    return<>
                                    <li>
                                        {item.Custom_Useful_Link?
                                        <Link to={item.Custom_Useful_Link}>{item.Useful_Link_Label}</Link>
                                        :
                                        <GetCTA link={item.Useful_Link_Menu && item.Useful_Link_Menu.Primary_URL} Label={item.Useful_Link_Label}/>
                                        }
                                    </li>                                                
                                    </>
                                    })}
                                </div>
                                :""}
                            </Col>
                        </Row>
                    </Container>
                
            </section>
        </React.Fragment>
    )
}
export default OfficeFaq
