import React, {useState,useEffect} from "react";
import {graphql } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/grunticon.scss";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import BreadCrumbs from '../Components/PropertyDetails/BackButton/BreadCrumbs';
import OfficeBanner from "../Components/OfficeDetails/OfficeBanner";
import OfficeDetailInfo from "../Components/OfficeDetails/DetailsPage/DetailsPage";
import OfficeTeam from "../Components/OfficeDetails/Team/Team";
import OfficeFaq from "../Components/OfficeDetails/Faq/Faq";
import DetailMap from "../Components/detail-map";
import FeaturedProperty from "../Components/PropertyDetails/PropertyList/FeaturedProperty";
import FeaturedPropertyLet from "../Components/PropertyDetails/PropertyList/FeaturedPropertyLet";
import Insights from '../Components/Home/Insights/Insights';
import SEO from "../Components/Seo/seo";
import StickyCTA from "../Components/sticky-cta";
const OfficeDetails = (props) => {
    const GQLPage = props.data.glstrapi?.ourOffice;
    const metadesc = "Our property experts in "+GQLPage.Name+" offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance in your property journey.";
    return (
        <React.Fragment>
            <div id={`body-bg-default`} className={`wrapper  office-details ${GQLPage.URL}`}>
                <div className="inner-header"><Header/></div>
                <SEO title={GQLPage.Meta_Title?GQLPage.Meta_Title:"Estate Agents in "+GQLPage.Name} description={metadesc?metadesc:GQLPage.Meta_Description} />
                <BreadCrumbs baselabel="Branches" baseurl="/branches" itemlabel={GQLPage.Name}/>
                <OfficeBanner data={GQLPage}/>
                <OfficeDetailInfo data={GQLPage} />
                <OfficeTeam id={GQLPage.id} url={GQLPage.URL} />
                <OfficeFaq faq={GQLPage.Office_FAQs} usefullinks={GQLPage.Office_Useful_Links} />
                {GQLPage.Latitude && GQLPage.Longitude &&
                    <DetailMap Latitude={GQLPage.Latitude} Longitude={GQLPage.Longitude}/>
                }
                <FeaturedProperty type="sales" area={GQLPage.Name} officeId={GQLPage.Office_CRM} officetitle={`Properties for sale in ${GQLPage.Name}`}/>
                <FeaturedPropertyLet type="lettings" area={GQLPage.Name} officeId={GQLPage.Office_CRM} officetitle={`Properties to rent in ${GQLPage.Name}`}/>
                <Insights/>
                <Footer searchtype="officedetails" area={GQLPage.Name} guide={GQLPage.Living_Module}/>
                <StickyCTA label="CALL US" type="mobile" number={GQLPage.Sales_Phone_Number}/>
            </div>
        </React.Fragment>
    )
}
export default OfficeDetails;
export const officeQuery = graphql`
query OfficeQuery($id: ID!) {
  glstrapi {
    ourOffice(id: $id) {    
        id
        Name
        URL
        Banner_Image {
          url
          alternativeText
        }
        Meta_Title
        Meta_Description
        Office_Address
        Sales_Phone_Number
        Lettings_Phone_Number
        Sales_Email_Address
        Lettings_Email_Address
        About_Office
        Get_Directions_Link
        Office_CRM
        Office_CTAs {
          CTA_Link {
            Primary_URL
          }
          CTA_Label
          CTA_Custom_Link
        }
        Opening_Hour_Image {
          url
          alternativeText
        }
        Office_Opening_Hours {
          Day
          Time
        }
        Office_Useful_Links {
            Useful_Link_Menu {
                Primary_URL
            }
            Useful_Link_Label
            Custom_Useful_Link
        }
        Living_Module {
          id
          Living_Module_Title
          Living_Module_Intro
          Living_Module_Info {
            Info_List_Title
            Info_List_Value
          }
          Living_Module_Image {
            alternativeText
            url
          }
          Select_Areaguide {
            Name
            URL
           }
        }
        Longitude
        Latitude
        Office_FAQs {
          id
          FAQ_Title
          FAQ_Content
        }
        imagetransforms
      }
}
}`

