import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import { AltSiteName, TeamBaseURL, ContactFormURL } from "../../utils";
import { OfficeTeam } from "../../../queries/common_use_query";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import Slider from "react-slick";
import "./Team.scss"
const settings1 = {
  dots: false,
  speed: 800,
  // slidesToShow: 1,
  // slidesToScroll: 1,
  infinite: false,
  arrows: false,
  mobileFirst: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: 4000,
      settings: {
        arrows: false,
        slidesToShow: 4
      }
    },
    {
      breakpoint: 1199,
      settings: {
        arrows: false,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 500,
      settings: {
        arrows: false,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 400,
      settings: {
        arrows: false,
        slidesToShow: 1
      }
    }
  ]
};
const Otherstaff = (props) => {
  const {loading, error, data} = OfficeTeam(props.id);
  return (
    <React.Fragment>
      <section className="team-row otherstaff">
        
          <Container>
          {data && data.teams.length > 0 && 
            <Row>
              <Col className="d-flex align-item-center">
                <div className="team-title">
                  <h2>Meet The Team</h2>
                </div>
                <div className="ms-auto">
                  <Link to="/about/meet-the-team/">
                  <button type="button" className="btn-circle">
                    <i className="icon-arrow"></i>
                  </button>
                  </Link>
                </div>
              </Col>
            </Row>
            }
            <div className="team-slider">
            {data && data.teams.length > 0 && 
              <>
              <Slider className="banner-imgslider d-block" {...settings1}>
                {data.teams.map((item, index) => {
                let processedImages = JSON.stringify({});
                if (item?.imagetransforms?.Staff_Image_Transforms) {
                processedImages = item.imagetransforms.Staff_Image_Transforms;
                }
                return<>
                <div className="team-item">
                  {/* <Link to={`${TeamBaseURL}/${item.URL}`} className="img-zoom"> */}
                  <ImageTransform imagesources={item.Staff_Image.url} renderer="srcSet" imagename="teams.Staff_Image.commontile" attr={{ alt: item.Staff_Image.alternativeText?item.Staff_Image.alternativeText:item.Name+' - '+item.Designation+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>                           
                  {/* </Link> */}
                  <div className="team-block">
                    <span className="date-text">{item.Name}</span>
                    <span className="profile">{item.Designation}</span>
                    <Link to={`${ContactFormURL}?tid=${item.id}`} className="mail">Send an email</Link>
                  </div>
                </div>
                </>
                })}
                
              </Slider>
              </>
            }

            </div>
          </Container>
        
      </section>

    </React.Fragment>
  )
}
export default Otherstaff
